<template>
  <div>
    <Navbar />
    <div class="mianContainer">
      <div
        class="photoSide"
        v-bind:style="{
          backgroundImage: 'url(' + server_url + '/' + data.image + ')',
        }"
      ></div>

      <div class="textSide align-items-center">
        <div class="align-items-center companyMiss">
          <h1
            class="mb-0 test"
            v-html="lang == 'ka' ? data.titleKA : data.titleEN"
          ></h1>

          <p
            v-html="lang == 'ka' ? data.descriptionKA : data.descriptionEN"
            class="text textScroll mt-3"
          ></p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import env from "../../../env.json";
import axios from "axios";
import Navbar from "./../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "./../../../components/Footer/newFooter/Footer.vue";

export default {
  name: "company",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      isLoading: false,
      data: {},
      lang: "ka",
      server_url: env.server_url,
    };
  },

  mounted() {
    this.isLoading = true;
    axios
      .get(`${env.host}/get/informations/mission`)
      .then((result) => {
        this.data = result.data.item;
        this.isLoading = false;
      })

      .catch((err) => console.log(err));
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
* {
  color: #666666;
}
.test >>> * {
  font-family: mtavruliBOLD;
  text-transform: uppercase;
}
.mianContainer {
  width: 100%;
  margin: auto;
  padding: 0px;
  max-width: 1400px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: calc(100vh - 190px);
}
.photoSide {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.photoSide img {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.textSide {
  align-items: center;
  display: flex;
  padding: 40px;
  color: #4d4d4c;
}
.text {
  font-size: 16px;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  display: none;
}
::-webkit-scrollbar-thumb {
  background: #7e549374;
  cursor: pointer;
}

::-webkit-scrollbar-trackbar-thumb {
  background: #7e549374;
}
::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}

.textScroll {
  overflow-y: scroll;
  max-height: 500px;
  padding-right: 10px;
}
.companyMiss h1 >>> * {
  color: #734c8a;
  font-family: mtavruliBOLD;
  font-size: 30px;
}

@media all and (max-width: 1500px) {
  .textSide {
    padding: 20px !important;
  }
}

@media all and (max-width: 992px) {
  .mianContainer {
    grid-template-columns: 1fr;
  }
  .photoSide {
    height: 100vw;
  }
  .textSide {
    padding: 0px;
  }
  .textScroll {
    overflow-y: hidden;
    max-height: none;
    padding-right: 0px;
  }
}
</style>
